export { default as LogoIcon } from './logo-icon.svg';
export { default as PackIcon } from './pack.svg';
export { default as SearchIcon } from './search.svg';
export { default as Logo_2 } from './logo-icon-2.svg';
export { default as Comment } from './comment.svg';
export { default as Currency } from './currency.svg';
export { default as Dialpad } from './dialpad.svg';
export { default as Pin } from './pin.svg';
export { default as Search } from './search.svg';
export { default as Sketch } from './sketch.svg';
export { default as New } from './new.svg';
export { default as BackArrow } from './back-arrow.svg';
export { default as ArrowRight } from './arrow-right.svg';
export { default as Pin_2 } from './pin_2.svg';
export { default as UserIcon } from './user.svg';
export { default as BankIcon } from './bank.svg';
export { default as Box_2 } from './box_2.svg';
export { default as Phone } from './phone.svg';
export { default as IconNumbers_1 } from './beautiful-numbers/icon-1.svg';
export { default as IconNumbers_2 } from './beautiful-numbers/icon-2.svg';
export { default as IconNumbers_3 } from './beautiful-numbers/icon-3.svg';
export { default as IconNumbers_4 } from './beautiful-numbers/icon-3.svg';
export { default as BackArrowBlue } from './chevron-down-blue.svg';
export { default as ArrowRightGray } from './arrow-right-gray.svg';
export { default as GlobePurple } from './globe-purple.svg';
export { default as PhonePurple } from './phone-purple.svg';
export { default as EmailPurple } from './email-purple.svg';
export { default as TablerPurple } from './tabler-purple.svg';
export { default as LogoVolna } from './logo-volna.svg';
export { default as Home } from './home-3.svg';
export { default as Frame } from './frame-mobile.svg';
export { default as Simcard } from './simcard2.svg';
export { default as MenuIcon } from './menu.svg';
export { default as VkIcon } from './vk.svg';
export { default as TelegramIcon } from './telegram.svg';
export { default as YouTubeIcon } from './youtube.svg';
export { default as VkIconMini } from './vk-icon-mini.svg';
export { default as YouTubeIconMini } from './youtube-icon-mini.svg';
export { default as DzenIcon } from './dzen.svg';
export { default as QRCode } from './qr-code.svg';
export { default as AppStore } from './app-store.svg';
export { default as GooglePlay } from './google-play.svg';
export { default as RightArrow } from './right-arrow.svg';
export { default as RuStore } from './ru-store.svg';
export { default as AppStore_2 } from './app-store-2.svg';
export { default as GooglePlay_2 } from './google-play-2.svg';
export { default as Mir } from './mir-payment.svg';
export { default as Visa } from './visa-payment.svg';
export { default as VisaBlack } from './visa-payment-black.svg';
export { default as Mastercard } from './mastercard-payment.svg';
export { default as Sbp } from './sbp-payment.svg';
export { default as MinusOctagon } from './minus-octagon.svg';
export { default as CheckboxRec } from './checkbox-rec.svg';
export { default as ChevronRight } from './chevron-right.svg';
export { default as Logo_3 } from './logo-icon-3.svg';
export { default as EmailIcon } from './email.svg';
export { default as GlobeIcon } from './globe.svg';
export { default as PhoneIcon } from './phone.svg';
export { default as WhitePhoneIcon } from './white-phone-icon.svg';
export { default as EllipsisPink } from './gradient-ellipsis-pink.svg';
export { default as EllipsisPurple } from './gradient-ellipsis-purple.svg';
export { default as CloseIcon } from './close-icon.svg';
export { default as Separator } from './separator.svg';
export { default as ArrowLeft } from './arrow-left.svg';
export { default as ArrowLeftWhite } from './arrow-left-white.svg';
export { default as ListRules } from './list-rules.svg';
export { default as CommentQuestion } from './comment-question.svg';
export { default as SimCard } from './simcard.svg';
export { default as UserRules } from './user-rules.svg';
export { default as ButtonLoop } from './button-loop.svg';
export { default as VkColor } from './vk-color.svg';
export { default as OkColor } from './ok-color.svg';
export { default as SkypeColor } from './skype-color.svg';
export { default as ViberColor } from './viber-color.svg';
export { default as YaMapsColor } from './ya-maps-color.svg';
export { default as YaNavigatorColor } from './ya-navigator-color.svg';
export { default as GisColor } from './2-gis-color.svg';
export { default as WAColor } from './whatsapp-color.svg';
export { default as TelegramColor } from './telegram-color.svg';
export { default as FilterIcon } from './filter-icon.svg';
export { default as MessangerIcon } from './messanger.svg';
export { default as GlobeColorIcon } from './globe-color.svg';
export { default as PhoneColorIcon } from './phone-color.svg';
export { default as VectorBanner } from './vector-banner.svg';
export { default as VectorBanner2 } from './vector-banner2.svg';
export { default as VectorBanner3 } from './vector-banner3.svg';
export { default as CopyIcon } from './copy.svg';
export { default as ArrowDownIcon } from './chevron-up.svg';
export { default as InfoIcon } from './info.svg';
export { default as TariffInfo } from './tariff-info.svg';
export { default as WiFi } from './speed.svg';
export { default as TV } from './tv.svg';
export { default as WiFiPurple } from './speed-purple.svg';
export { default as TVPurple } from './tv-purple.svg';
export { default as ShoppingCart } from './shopping-cart.svg';
export { default as ArrowDropDown } from './arrow_drop_down.svg';
export { default as ArrowDropUp } from './arrow_drop_up.svg';
export { default as StopWatchIcon } from './stopwatch.svg';
export { default as TvIcon } from './tv.svg';
export { default as ServiceInfo } from './service-info.svg';
export { default as Cross } from './cross.svg';
export { default as Checked } from './checked.svg';
export { default as TimeIcon } from './time.svg';
export { default as SalesPointIcon } from './sales-point.svg';
export { default as DeliveryIcon } from './delivery.svg';
export { default as OnlinePayment } from './online-payment.svg';
export { default as Truck } from './truck.svg';
export { default as Restore } from './restore.svg';
export { default as ErrorIcon } from './error.svg';
export { default as CheckboxRecHI } from './checkbox-rec-hi.svg';
export { default as GreyTimeIcon } from './grey-time.svg';
export { default as Diamond } from './diamond.svg';
export { default as DiamondGrey } from './diamond-grey.svg';
export { default as ReturnIcon } from './return.svg';
export { default as PlugIcon } from './tabler-icon-plug.svg';
export { default as PlugIconPurple } from './tabler-icon-plug-purple.svg';
export { default as RouterIcon } from './tabler-icon-router.svg';
export { default as RouterIconPurple } from './tabler-icon-router-purple.svg';
export { default as RefreshIcon } from './refresh-cw.svg';
export { default as RubIcon } from './rub-tabler-icon-coin.svg';
export { default as ArrowUp } from './vector-top.svg';
export { default as ArrowDown } from './vector-bottom.svg';
export { default as PhoneTariff } from './phone_tariff.svg';
export { default as InfinityIcon } from './infinity.svg';
export { default as InfinityIconWhite } from './infinity-white.svg';
export { default as InfinitySmallIcon } from './infinity-small.svg';
export { default as InfinitySmallPurpleIcon } from './infinity-small-purple.svg';
export { default as PCLogo } from './pc_logo.svg';
export { default as Telegram } from './telegram-icon.svg';
export { default as PlayIcon } from './play.svg';
export { default as Google } from './google.svg';
export { default as Yandex } from './yandex.svg';
export { default as IOS } from './ios.svg';
export { default as Resource } from './icon-resource.svg';
export { default as Android } from './android.svg';
export { default as Checkbox } from './checkbox.svg';
export { default as SimcardOption } from './simcard-option.svg';
export { default as WhiteInfo } from './white-info.svg';
export { default as HomeIcon } from './filled-home.svg';
export { default as IconClose } from './kaspersky/icon-close.svg';
export { default as IconComplite } from './kaspersky/icon-complite.svg';
export { default as IconUnion } from './kaspersky/icon-union.svg';
export { default as MagentaHeart } from './magenta-heart.svg';
export { default as BulletMagenta } from './bullet-magenta.svg';
export { default as BulletBlue } from './bullet-blue.svg';
export { default as ShoppingDark } from './shopping-dark.svg';
export { default as Reset } from './reset.svg';
export { default as ArrowLeftDark } from './arrow-left-dark.svg';
export { default as StarIcon } from './star.svg';
export { default as QuestionIcon } from './question.svg';
export { default as TelegramIconBlue } from './telegram-icon-blue.svg';
