import { FC, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import cn from 'classnames';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import { carouselMessages } from '@/widgets/carousel/constants';
import styles from './carousel-with-title.module.scss';

export interface CarouselWithTitleStyles {
  wrapper?: string;
  title?: string;
  swiper?: string;
}

interface CarouselWithTitleProps {
  slides: ReactNode[];
  title: ReactNode;
  classNames?: CarouselWithTitleStyles;
  swiperOptions?: SwiperOptions;
}

export const CarouselWithTitle: FC<CarouselWithTitleProps> = ({ title, slides, swiperOptions, classNames }) => {
  const { isDesktopMax } = useMediaQuery();

  return (
    <div className={cn(styles.wrapper, classNames?.wrapper)}>
      {typeof title === 'string' ? <h4 className={cn(styles.title, classNames?.title)}>{title}</h4> : title}
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
        a11y={carouselMessages}
        className={cn(styles.swiper, classNames?.swiper)}
        navigation={!!isDesktopMax}
        pagination={{ clickable: true }}
        loop
        breakpoints={{
          265: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 'auto',
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        { ...swiperOptions }
      >
        {slides?.map((data, idx) => (
          <SwiperSlide key={idx}>
            {data}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
