import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface State {
  data: string;
  currIndex: number;
  isSmsLoading: boolean;
  isCookiesAccepted: boolean;
  privacy_policy: string;
}

const initialState: State = {
  data: '',
  currIndex: 0,
  isSmsLoading: false,
  isCookiesAccepted: false,
  privacy_policy: '',
};

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeCurrIndex: (state, action: PayloadAction<number>) => {
      state.currIndex = action.payload;
    },
    changeSmsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSmsLoading = action.payload;
    },
    acceptCookies: (state) => {
      state.isCookiesAccepted = true;
    },
    changePrivacyPolicy: (state, action: PayloadAction<string>) => {
      state.privacy_policy = action.payload;
    },
  },
});

export const { changeCurrIndex, changeSmsLoading, acceptCookies, changePrivacyPolicy } = AppSlice.actions;

export default AppSlice.reducer;
