import { FC, ReactNode, memo, useEffect, useState } from 'react';
import { FocusTrap, PinInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import cn from 'classnames';
import { CONSTANTS } from '@/widgets/form/constants';
import { Tooltip } from '../..';
import { GreyTimeIcon } from '@/shared/assets/svg';
import { formatSeconds } from '@/shared/lib/format-seconds';
import { formatPhoneNumber } from '@/shared/lib/format-phone-number';
import { getMyTypeError } from '@/shared/lib/get-my-type-error';
import { storage } from '@/shared/lib/storage';
import { CheckCodeResponseError } from '@/store/api/sms-auth/sms-auth.type';
import { useReservationMutation } from '@/store/api/order/order.api';
import { useCheckCodeQuery, useSendSmsMutation } from '@/store/api/sms-auth/sms-auth.api';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { changeSmsLoading } from '@/store/slice/app.slice';
import { setPinConfirmedNumber } from '@/store/slice/beautiful-number';
import { reachGoal } from '@/shared/lib/ym';
import { YM } from '@/shared/constants/yandex-metrika';
import { ORDER_PARAMS } from '@/modules/tariff/shared/requests/constants';
import styles from './sms-panel.module.scss';

interface SmsPanelProps {
  contact_phone: string;
  footer?: ReactNode;
  opened?: boolean;
  disabled?: boolean;
  changeContactPhone?: () => void;
  setDisabled?: (b: boolean) => void;
  isMobileQualityModal?: boolean;
}

const SmsPanel: FC<SmsPanelProps> = memo(({ contact_phone, footer, opened, changeContactPhone, setDisabled, isMobileQualityModal }) => {
  const [timer, setTimer] = useState(0);
  const [value, setValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [reservationErrorMessage, setReservationErrorMessage] = useState('');
  const { selectedNumber, pinConfirmedNumber } = useAppSelector((state) => state.beautifulNumber);
  const [active, { toggle }] = useDisclosure(false);

  const dispatch = useAppDispatch();

  const isPinCodeActivate = pinConfirmedNumber === selectedNumber?.phone_id;

  const [sendSms] = useSendSmsMutation();

  const {
    data: checkCodeData,
    isError: checkCodeIsError,
    isSuccess: checkCodeIsSuccess,
    error: checkCodeError,
    isLoading: checkCodeIsLoading,
  } = useCheckCodeQuery(
    { contact_phone, sms_code: value, source_id: ORDER_PARAMS.SOURCE_ID },
    { skip: value.length !== 4 || !opened || contact_phone.length < 10 },
  );

  const isCodeChecked = checkCodeIsSuccess && checkCodeData.result === true;
  const isNumberSelected = selectedNumber && selectedNumber.msisdn;

  const [
    reservation,
    {
      data: reservationData,
      isLoading: reservationIsLoading,
      isError: reservationIsError,
      error: reservationError,
      isSuccess: reservationIsSucces,
      reset: resetReservation,
    },
  ] = useReservationMutation();

  useEffect(() => {
    dispatch(changeSmsLoading(reservationIsLoading));
  }, [reservationIsLoading]);

  useEffect(() => {
    if (opened && !isPinCodeActivate) {
      const sessionObject = storage.get('contact_phone');
      const parsedObject = sessionObject ? JSON.parse(sessionObject) : { expirationTime: Date.now() + 95000 };
      const expirationTime = (parsedObject.expirationTime - Date.now()) / 1000;
      if (expirationTime > 0) {
        setTimer(Math.round(expirationTime));
      }
      if (expirationTime <= 0) {
        storage.remove('contact_phone');
      }
      if (!active) {
        toggle();
      }
    }
  }, [opened, isPinCodeActivate]);

  useEffect(() => {
    if (timer > 0 && !isPinCodeActivate) {
      const interval = setInterval(() => setTimer((timer: number) => timer - 1), 1000);
      if (isPinCodeActivate) return () => clearInterval(interval);

      return () => clearInterval(interval);
    }
  }, [timer, isPinCodeActivate]);

  useEffect(() => {
    if ((checkCodeIsSuccess && checkCodeData.result === false) || checkCodeIsError) {
      // Проверка кода завершилась ошибкой
      reachGoal(YM.input_sms_pass_step4_failure);
      setDisabled && setDisabled(true);
      const errMsg = getMyTypeError<CheckCodeResponseError>(checkCodeError, 'status');
      if (checkCodeIsError && errMsg) {
        setErrorMessage(errMsg?.data?.detail || 'Не удалось проверить код');
      }
    }
    if (isCodeChecked) {
      // Проверка кода успешна
      reachGoal(YM.input_sms_pass_step4_success);
      setErrorMessage('');

      if (isNumberSelected && !isMobileQualityModal) {
        reservation({
          msisdn: selectedNumber.msisdn,
          pht_id: selectedNumber.pht_id,
          source_id: ORDER_PARAMS.SOURCE_ID,
          contact_phone: contact_phone,
        });
      } else {
        setDisabled?.(false);
      }
    }
  }, [
    checkCodeData,
    checkCodeError,
    checkCodeIsError,
    checkCodeIsSuccess,
    isCodeChecked,
    isNumberSelected,
    reservation,
    selectedNumber,
    setDisabled,
    isMobileQualityModal
  ]);

  useEffect(() => {
    if (reservationIsError && reservationError) {
      const errMsg = getMyTypeError<CheckCodeResponseError>(reservationError, 'status');
      if (errMsg) {
        const isReservedError = errMsg?.data?.detail?.includes('номер зарезервирован');
        const statusCode = Number(errMsg.status);

        if (isReservedError) {
          setReservationErrorMessage(errMsg?.data?.detail || '');
        } else if (statusCode >= 400 && statusCode < 600) {
          setReservationErrorMessage('Произошла ошибка, попробуйте позже');
        }
      }
    } else if (reservationIsSucces && !reservationData?.status) {
      setReservationErrorMessage(reservationData?.msg || reservationData?.detail || 'Не удалось зарезервировать номер');
    }
  }, [reservationIsError, reservationError, reservationData, reservationIsSucces]);

  useEffect(() => {
    if (reservationIsSucces && reservationData && reservationData.status === true) {
      setDisabled?.(false);
      setReservationErrorMessage('');
      dispatch(setPinConfirmedNumber(selectedNumber?.phone_id || null));

      storage.remove('contact_phone');
    }
  }, [reservationData, reservationIsSucces]);

  useEffect(() => {
    setValue('');
    setErrorMessage('');
  }, [contact_phone]);

  const restart = () => {
    sendSms({ contact_phone, source_id: ORDER_PARAMS.SOURCE_ID });
    const sessionObject = {
      contact_phone,
      expirationTime: Date.now() + 95000,
    };
    storage.set('contact_phone', JSON.stringify(sessionObject));
    setValue('');
    setTimer(95);
    setErrorMessage('');
    setReservationErrorMessage('');
  };

  return (
    <>
      <div className={styles.description}>
        {CONSTANTS.sms.description} <span>{formatPhoneNumber(contact_phone, false)}.</span>
      </div>
      <div className={styles.wrapper}>
        <FocusTrap active={active}>
          <PinInput
            disabled={isCodeChecked || isPinCodeActivate}
            inputMode='numeric'
            inputType='number'
            placeholder=''
            classNames={{ input: styles.input, root: styles.input__root }}
            error={(checkCodeIsSuccess && checkCodeData.result === false) || checkCodeIsError}
            value={value}
            onChange={setValue}
            data-autofocus
          />
        </FocusTrap>

        {errorMessage && <span className={styles.input__error}>{errorMessage}</span>}

        {reservationErrorMessage && <span className={styles.input__error}>{reservationErrorMessage}</span>}
      </div>

      {(checkCodeIsSuccess && checkCodeData.result) || isPinCodeActivate ? null : timer > 0 ? (
        <div className={cn(styles.button, styles.timer)}>
          <GreyTimeIcon />
          <span>{`Получить код еще раз через ${formatSeconds(timer)}`}</span>
        </div>
      ) : (
        <>
          <div className={styles.button} onClick={restart}>
            {CONSTANTS.sms.code_again}
          </div>
          <Tooltip
            withinPortal={false}
            label={
              <div className={styles.tooltip}>
                <span className={styles.tooltip__title}>Что делать, если не приходит SMS?</span>
                <span className={styles.tooltip__text}>
                  Вариант 1 <br /> Попробуйте получить код через 2-3 минуты
                </span>
                <span className={styles.tooltip__button} onClick={restart}>
                  Получите код еще раз
                </span>
                <span className={styles.tooltip__text}>
                  Вариант 2 <br /> Проверьте, правильно ли вы указали контактный номер телефона или укажите другой номер
                </span>
                <span className={styles.tooltip__button} onClick={changeContactPhone}>
                  Изменить номер телефона
                </span>
              </div>
            }
          >
            <div className={styles.button}>{CONSTANTS.sms.not_coming}</div>
          </Tooltip>
        </>
      )}

      {footer}
    </>
  );
});

SmsPanel.displayName = 'SmsPanel';
export { SmsPanel };
