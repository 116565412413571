import React from 'react';
import { DropzoneProps, FileWithPath, Dropzone as MantineDropzone } from '@mantine/dropzone';
import { CloseBtn, Paperclip } from '@/shared/assets/icons';
import cn from 'classnames';
import styles from './dropzone.module.scss';

type Props = DropzoneProps & {
  wrapperClassName?: string;
  children?: React.ReactNode;
  multiple?: boolean;
  files: FileWithPath[];
};

export const Dropzone = ({ wrapperClassName, children, onDrop: onDropProp, files, ...props }: Props) => {
  if (files.length > 0) {
    return (
      <div className={styles.result}>
        <Paperclip />
        <div className={styles.fileName}>{files[0].path}</div>
        <div
          className={styles.closeButton}
          onClick={() => {
            onDropProp?.([]);
          }}
        >
          <CloseBtn />
        </div>
      </div>
    );
  }

  const handleDrop = (acceptedFiles: FileWithPath[]) => {
    onDropProp?.(acceptedFiles);
  };

  return (
    <MantineDropzone
      onDrop={handleDrop}
      classNames={{
        root: cn(styles.wrapper, wrapperClassName),
      }}
      maxSize={5 * 1024 ** 2}
      {...props}
    >
      {children}
    </MantineDropzone>
  );
};
