import { StrapiQueryParams } from '@/shared/types/strapi.types';
import { addingParameters } from '@/shared/lib/adding-parameters';
import { getOrigin } from '@/shared/lib/base-query';
import { formatTimeDifference } from '@/shared/lib/utils';

export const fetchEndpointData = async <T>(
  path: string,
  params: StrapiQueryParams,
  DEBUG_ID?: string | number,
): Promise<T | undefined> => {
  const startTime = new Date();
  const URL = getOrigin() + addingParameters({ params: params, path: path });
  console.log(`================= SERVER SIDE FETCH (${DEBUG_ID || ':) '}) ================`);
  console.log('URL:', URL);

  try {
    const response = await fetch(URL, {
      headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();

    return json;
  } catch (error) {
    console.log('ERROR getting', URL);
    console.error(error);
  } finally {
    const endTime = new Date();
    const duration = formatTimeDifference(startTime, endTime);
    console.log(`=============== FETCH END (${DEBUG_ID || ':) '}). DURATION: ${duration} ==============`);
  }
};
