import { QuestionPage } from './mobile-communication-quality.types';
// import '@@/assets/img/mobile-communication-quality/mobile-operator-name.png'

export const phones = [
  'Honor 70 Pro+',
  'Honor 80 Pro',
  'Honor 90',
  'Honor Magic 5 Pro',
  'Honor Magic4 Ultimate',
  'Honor Play 30',
  'Honor X7a',
  'Honor X9A',
  'Huawei Mate 50',
  'Huawei Mate 60',
  'Huawei Nova 11',
  'Huawei Nova 9 SE',
  'Huawei Nova Y70',
  'Huawei P50 Pro',
  'Huawei P60 Pocket',
  'Huawei P60 Pro',
  'Infinix Hot 11 Play',
  'Infinix Hot 20',
  'Infinix Hot 30',
  'Infinix Note 12 Pro',
  'Infinix Note 30',
  'Infinix Smart 7',
  'Infinix Zero 20',
  'Infinix Zero Ultra',
  'OPPO A16',
  'OPPO A78 5G',
  'OPPO A98 5G',
  'OPPO Find N3',
  'OPPO Reno7',
  'OPPO Reno8 T 5G',
  'POCO F4 GT',
  'POCO M4 Pro',
  'POCO M5s',
  'POCO X5 Pro',
  'Realme 10',
  'Realme 11 Pro+',
  'Realme 8 Pro',
  'Realme 9 Pro+',
  'Realme C33',
  'Realme C55',
  'Realme GT Neo 5',
  'Realme Narzo 60 Pro',
  'Realme Note 50 (64 ГБ)',
  'Redmi 10C',
  'Redmi 12 (256 ГБ)',
  'Redmi 13C (256 ГБ)',
  'Redmi 9A',
  'Redmi 9T',
  'Redmi A2+ (64 ГБ)',
  'Redmi K50i',
  'Redmi K60 Ultra',
  'Redmi Note 10 Pro',
  'Redmi Note 11',
  'Redmi Note 12 Pro',
  'Redmi Note 9 Pro',
  'Samsung Galaxy A04s',
  'Samsung Galaxy A13',
  'Samsung Galaxy A14 5G',
  'Samsung Galaxy A23 5G',
  'Samsung Galaxy A34 5G',
  'Samsung Galaxy A54 5G',
  'Samsung Galaxy M14 5G',
  'Samsung Galaxy M34',
  'Samsung Galaxy S20 FE',
  'Samsung Galaxy S21 FE 5G',
  'Samsung Galaxy S23 Ultra',
  'Samsung Galaxy Z Flip5',
  'Samsung Galaxy Z Fold5',
  'Tecno Camon 19 Pro',
  'Tecno Camon 20 Premier',
  'Tecno Phantom X2 Pro',
  'Tecno Pop 7 Pro',
  'Tecno Pova 5',
  'Tecno Spark 10 Pro',
  'Tecno Spark 9',
  'Vivo V25 Pro',
  'Vivo V27',
  'Vivo X80 Pro',
  'Vivo Y21',
  'Vivo Y22s',
  'Vivo Y35',
  'Vivo Y53s',
  'Xiaomi 11T Pro',
  'Xiaomi 12T Pro',
  'Xiaomi 13 Pro',
  'Xiaomi 13T Pro',
  'Xiaomi Civi 3',
  'Xiaomi Mi 10T Pro',
  'Xiaomi Mi 11 Ultra',
  'Xiaomi Redmi 10',
  'Xiaomi Redmi K60',
  'iPhone 11',
  'iPhone 12',
  'iPhone 13 (128 ГБ)',
  'iPhone 14 (128 ГБ)',
  'iPhone 15 (128 ГБ)',
  'iPhone 15 Pro (256 ГБ)',
  'iPhone 15 Pro Max (256 ГБ)',
  'iPhone SE (2024)',
  'iPhone XR',
];

export const producers = [
  'Honor',
  'Huawei',
  'Infinix',
  'OPPO',
  'POCO',
  'Realme',
  'Redmi',
  'Samsung',
  'Tecno',
  'Vivo',
  'Xiaomi',
  'iPhone',
];

export const storageKey = 'connectionQualityModalForm';

export const questions: QuestionPage[] = [
  {
    id: '0', // Старт
    title: 'Личные данные',
    subtitle: 'Укажите номер телефона Волны, и мы пришлем вам код в SMS.',
    questions: [
      {
        id: '0_1',
        label: 'Как к вам обращаться?',
        placeholder: 'Ваше имя',
        type: 'textInput',
        required: true,
      },
      {
        id: '0_2',
        label: 'Номер телефона',
        placeholder: '+7 (___) ___-__-__',
        type: 'phoneInput',
        required: true,
      },
    ],
    hasPrevButton: false,
    hasNextButton: true,
    nextButtonLabel: 'Получить код',
    nextId: '0_5', // Ввод SMS
    // nextId: '1', // Пропускаем ввод SMS
  },
  {
    id: '0_5',
    title: 'Одноразовый SMS-код',
    subtitle: 'Введите 4-значный код из SMS, который мы отправили на номер',
    questions: [],
    hasSMSCode: true,
    hasPrevButton: false,
    hasNextButton: true,
    nextButtonLabel: 'Начать опрос',
    nextId: '1', // Марка и модель смартфона
    prevId: '0', // Старт
  },
  {
    id: '1',
    title: 'Марка и модель вашего смартфона',
    questions: [
      {
        id: '1_1',
        label: 'Выбрано из списка',
        type: 'select',
        /* options: phones.map((phone, index) => ({
          id: index,
          value: phone,
        })),
        placeholder: 'Выберите устройство', */
        options: producers.map((producer, index) => ({
          id: index,
          value: producer,
        })),
        placeholder: 'Выберите производителя',
      },
      {
        id: '1_2',
        label: 'Указано вручную',
        hideLabel: true,
        placeholder: 'Укажите устройство вручную',
        type: 'textInput',
      },
    ],
    hasOrWord: true,
    hasPrevButton: true,
    hasNextButton: true,
    required: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '2', // На данный момент чаще всего вы испытываете сложности с
    prevId: '0_5', // Ввод SMS
  },
  {
    id: '2',
    title: 'На данный момент чаще всего вы испытываете сложности с:',
    questions: [
      {
        id: '2_1',
        type: 'radioButton',
        options: [
          {
            id: '2_1_1',
            value: 'Мобильным интернетом',
          },
          {
            id: '2_1_2',
            value: 'Голосовой связью',
          },
        ]
      }
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: {
      '2': {
        '2_1_1': '3_1', // С какой сложностью вы столкнулись?
        '2_1_2': '3',   // При каких вызовах испытываете сложности?
      }
    },
    prevId: '1',
  },
  {
    id: '3', // Ветка "Голосовая связь"
    title: 'При каких вызовах испытываете сложности?',
    questions: [
      {
        id: '3_1',
        text: 'При входящих',
        type: 'checkbox',
      },
      {
        id: '3_2',
        text: 'При исходящих',
        type: 'checkbox',
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '4', // С какими проблемами сталкиваетесь?
    prevId: '2', // На данный момент чаще всего вы испытываете сложности с
  },
  {
    id: '4',
    title: 'С какими проблемами сталкиваетесь?',
    questions: [
      {
        id: '4_1',
        text: 'Гудки идут, собеседник берёт трубку, но голос будто зажевало плёнку',
        type: 'checkbox',
      },
      {
        id: '4_2',
        text: 'Гудки пошли, но оборвались',
        type: 'checkbox',
      },
      {
        id: '4_3',
        text: 'На экране светится, что звонок идёт, но в трубке тишина и нет даже гудков',
        type: 'checkbox',
      },
      {
        id: '4_4',
        text: 'Набрал номер, но до гудков проходит длительное время',
        type: 'checkbox',
      },
      {
        id: '4_5',
        text: 'Вы уверены, что собеседник в сети, но вам говорят, что он вне зоны действия сети',
        type: 'checkbox',
      },
      {
        id: '4_6',
        text: 'Сказал что-то, а до собеседника это дошло только через минуту',
        type: 'checkbox',
      },
      {
        id: '4_7',
        text: 'До меня не могут дозвониться, получаю СМС "Вам звонили"',
        type: 'checkbox',
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '5', // Какая сеть у вас сейчас?
    prevId: '3', // При каких вызовах испытываете сложности?
  },
  {
    id: '5',
    title: 'Какая сеть у вас сейчас?',
    questions: [
      {
        id: '5_1',
        type: 'radioButton',
        options: [
          {
            id: '1',
            value: '2G (E/EDGE)',
          },
          {
            id: '2',
            value: '3G (H)',
          },
          {
            id: '3',
            value: '4G (LTE)',
          },
          {
            id: '4',
            value: 'HSDPA/HSDPA+',
          },
          {
            id: '5',
            value: 'Нет сети',
          },
          {
            id: '6',
            value: 'Затрудняюсь ответить',
          },
        ],
      }
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '6', // В какой сети зарегистрирован смартфон?
    prevId: {
      '2': {
        '2_1_1': '3_1', // Выбрано "Мобильным интернетом",
        '2_1_2': '4',   // Выбрано "Голосовой связью"
      },
    },
  },
  {
    id: '6',
    title: 'В какой сети зарегистрирован смартфон?',
    questions: [
      {
        id: '6_1',
        placeholder: 'Название сети',
        type: 'textInput',
        image: '/assets/img/mobile-communication-quality/mobile-operator-name.png',
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '7', // На значке качества связи все палочки, но голос собеседника прерывается?
    prevId: '5', // Какая сеть у вас сейчас?
  },
  {
    id: '7',
    title: 'На значке качества связи все палочки, но голос собеседника прерывается?',
    questions: [
      {
        id: '7_1',
        type: 'radioButton',
        options: [
          {
            id: '1',
            value: 'Да',
          },
          {
            id: '2',
            value: 'Нет',
          },
        ],
      },
      // {
      //   id: '7_3',
      //   text: 'Другое',
      //   type: 'radioButton',
      // },
      // {
      //   id: '7_4',
      //   type: 'textarea',
      //   placeholder: 'Опишите подробнее',
      // },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '8', // Где сталкиваетесь с проблемой?
    prevId: '6', // В какой сети зарегистрирован смартфон?
  },
  {
    id: '8',
    title: 'Где сталкиваетесь с проблемой',
    questions: [
      {
        id: '8_1',
        type: 'radioButton',
        options: [
          {
            id: '8_1_1',
            value: 'Внутри помещения',
          },
          {
            id: '8_1_2',
            value: 'Снаружи помещения',
          },
        ],
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: {
      '8': {
        '8_1_1': '8_5', // На каком этаже?
        '8_1_2': '9',   // Укажите адрес, по которому вы сталкиваетесь с проблемой
      }
    },
    prevId: '7',
  },
  {
    id: '8_5',
    title: 'На каком этаже?',
    questions: [
      {
        id: '8_5_1',
        label: 'Укажите этаж',
        type: 'textInput',
        labelForHTML: null,
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '9', // Укажите адрес, по которому вы сталкиваетесь с проблемой
    prevId: '8', // Где сталкиваетесь с проблемой
  },
  {
    id: '9',
    title: 'Укажите адрес, по которому вы сталкиваетесь с проблемой',
    questions: [
      {
        id: '9_1',
        label: 'Укажите адрес',
        type: 'textInput',
        labelForHTML: null,
      },
    ],
    hasMap: true,
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: {
      '2': {
        '2_1_1': '9_5', // Во время чего испытывали трудности?
        '2_1_2': '10',  // Как часто сталкиваетесь с проблемой?
      }
    },
    prevId: '8', // Где сталкиваетесь с проблемой
  },
  {
    id: '10',
    title: 'Как часто сталкиваетесь с проблемой?',
    questions: [
      {
        id: '10_1',
        type: 'radioButton',
        options: [
          {
            id: '10_1_1',
            value: 'Недавно появилась',
          },
          {
            id: '10_1_2',
            value: 'Впервые сталкиваюсь с проблемой',
          },
          {
            id: '10_1_3',
            value: 'Постоянная проблема',
          },
        ],
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: {
      '10': {
        '10_1_1': '10_5', // Укажите дату с какого момента начались проблемы
        '10_1_2': '11',   // Данные сети (необязательно)
        '10_1_3': '11',   // Данные сети (необязательно)
      }
    },
    prevId: {
      '2': {
        '2_1_1': '9_5', // Выбрано "Мобильным интернетом" - Во время чего испытывали трудности?
        '2_1_2': '9',   // Выбрано "Голосовой связью" - Укажите адрес, по которому вы сталкиваетесь с проблемой
      },
    },
  },
  {
    id: '10_5',
    title: 'Укажите дату с какого момента начались проблемы',
    questions: [
      {
        id: '10_5_1',
        label: 'Укажите дату',
        type: 'textInput',
        labelForHTML: null,
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '11', // Данные сети (необязательно)
    prevId: '10', // Как часто сталкиваетесь с проблемой?
  },
  {
    id: '11',
    title: 'Данные сети (необязательно)',
      subtitle: `Эти данные помогут нам решить проблему быстрее и качественнее. Чтобы мы их узнали, зайдите на сайт 
      <a href="https://www.speedtest.net" target="_blank">SpeedTest</a>,
      замерьте в нём показатели <b>скорость скачивания</b> и <b>пинг</b> и укажите их здесь.`,
    questions: [
      {
        id: '11_1',
        label: 'Скорость скачивания',
        type: 'textInput',
        placeholder: 'Укажите скорость',
      },
      {
        id: '11_2',
        label: 'Ping',
        type: 'textInput',
        placeholder: 'Укажите пинг',
      },
    ],
    instructionSteps: [
      {
        id: '1',
        label: 'Зайдите на сайт <a href="https://www.speedtest.net" target="_blank">SpeedTest</a>',
      },
      {
        id: '2',
        label: 'Нажмите на кнопку Начать/Go',
        image: '/assets/img/mobile-communication-quality/speed.png',
      },
      {
        id: '3',
        label: 'После замера вы увидите два параметра **— скорость скачивания** и **пинг**',
        image: '/assets/img/mobile-communication-quality/ping.png',
      },
      {
        id: '4',
        label: 'Укажите эти два значения здесь и нажмите кнопку Готово',
      },
    ],
    hasPrevButton: true,
    hasNextButton: false,
    nextButtonLabel: 'Готово',
    prevButtonLabel: 'Назад',
    prevId: '10', // Как часто сталкиваетесь с проблемой?
  },
  {
    id: '3_1', // Ветка "Мобильный интернет"
    title: 'С какой сложностью вы столкнулись?',
    questions: [
      {
        id: '3_1_1',
        text: 'Не могу войти в интернет',
        type: 'checkbox',
      },
      {
        id: '3_1_2',
        text: 'Не могу зайти на определённый сайт',
        type: 'checkbox',
        hasPriority: true,
      },
      {
        id: '3_1_3',
        text: 'Низкая скорость интернета (медленно грузится контент, зависания при просмотре видео или отправке файлов)',
        type: 'checkbox',
      },
      {
        id: '3_1_4',
        text: 'Есть проблемы при звонках/видеозвонках через интернет или при просмотре прямой трансляции',
        type: 'checkbox',
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: {
      '3_1': {
        '3_1_1': '5',   // Какая сеть у вас сейчас?
        '3_1_2': '4_1', // С каким сайтом/сервисом/приложением возникают трудности?
        '3_1_3': '5',   // Какая сеть у вас сейчас?
        '3_1_4': '5',   // Какая сеть у вас сейчас?
      }
    },
    prevId: '2', // На данный момент чаще всего вы испытываете сложности с
  },
  {
    id: '4_1',
    title: 'С каким сайтом/сервисом/приложением возникают трудности?',
    questions: [
      {
        id: '4_1_1',
        type: 'textarea',
        placeholder: 'Опишите подробнее',
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '5', // Какая сеть у вас сейчас?
    prevId: '3_1', // С какой сложностью вы столкнулись?
  },
  {
    id: '9_5',
    title: 'Во время чего испытывали трудности?',
    questions: [
      {
        id: '9_5_1',
        type: 'checkbox',
        text: 'Прямая трансляция',
      },
      {
        id: '9_5_2',
        type: 'checkbox',
        text: 'Видеозвонок через приложение',
      },
      {
        id: '9_5_3',
        type: 'checkbox',
        text: 'Скачивание данных',
      },
      {
        id: '9_5_4',
        type: 'checkbox',
        text: 'Загрузка данных онлайн',
      },
      {
        id: '9_5_5',
        type: 'checkbox',
        text: 'Просмотр видео',
      },
      {
        id: '9_5_6',
        type: 'checkbox',
        text: 'Онлайн игра',
      },
    ],
    hasPrevButton: true,
    hasNextButton: true,
    nextButtonLabel: 'Далее',
    prevButtonLabel: 'Назад',
    nextId: '10', // Как часто сталкиваетесь с проблемой?
    prevId: '9'   // Укажите адрес, по которому вы сталкиваетесь с проблемой
  },
];
