import { memo, useState } from 'react';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@/shared/lib/use-media-query';
import Image from 'next/image';
import { Path } from '@/shared/constants/links';
import { VkIcon, TelegramIcon, YouTubeIcon, DzenIcon, PCLogo } from '@/shared/assets/svg';
import { Button } from '@mantine/core';
import Link from 'next/link';
import { LINKS } from '@/shared/constants/links';
import { ConnectionQualityModal } from '@/modules/mobile-communication-quality/quality-modal/quality-modal';
import cn from 'classnames';
import { Socials } from './socials';
import styles from './footer-cards.module.scss';

export const FooterCardsBlock = memo(() => {
  const { isMobile, isDesktopMax } = useMediaQuery();
  const [showConnectionQualityModal, setShowConnectionQualityModal] = useState(false);
  const router = useRouter();
  const mobileAppPage = router.pathname.includes(Path.faq.app);
  const isFaqPage = router.pathname.includes(Path.faq.root) && !mobileAppPage;

  const withoutFooter = [
    Path.skatepark,
    Path.litres,
    Path.promotions,
    Path.services.sberbox,
    Path.services.viju,
    Path.kaspersky,
    Path.inviteFriend,
    Path.inviteFriend,
    Path.faq.app,
    Path.services.premier,
    Path.volnaSale
  ].includes(router.pathname);

  const faqPageStyle = isDesktopMax && isFaqPage;

  if (withoutFooter) {
    return null;
  }

  const openConnectionQualityModal = () => setShowConnectionQualityModal(true);
  const handleCloseConnectionQualityModal = () => setShowConnectionQualityModal(false);

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper__faqPage]: isFaqPage,
        [styles.resetHeight]: withoutFooter,
        // [styles.wrapper__mobApp]: mobileAppPage
      })}
    >
      {!isFaqPage && (
        <>
          <Link href={Path.faq.root} className={styles.questionAnswerCard}>
            <div className={styles.questionAnswerTop}>
              <div className={styles.title}>Есть вопрос?</div>
              <div className={styles.text}>У нас уже готов ответ</div>
            </div>
            <Button
              type='button'
              classNames={{ root: cn(styles.btn__root /* { [styles.btn__root__mobApp]: mobileAppPage } */) }}
            >
              Узнать
            </Button>
          </Link>

          <div
            onClick={openConnectionQualityModal}
            className={cn(styles.questionAnswerCard, styles.questionAnswerCard__problemsCard)}
          >
            <div className={styles.questionAnswerTop}>
              <div className={styles.title}>Проблемы со связью?</div>
              <div className={styles.text}>Ответь на несколько вопросов, а мы поможем</div>
            </div>
            <Button
              onClick={openConnectionQualityModal}
              classNames={{ root: cn(styles.btn__root, styles.btn__root__problems) }}
            >
              Пройти опрос
            </Button>
          </div>
        </>
      )}
      {!withoutFooter /*  && !mobileAppPage */ ? (
        <Link href={Path.faq.app} className={cn(styles.mobAppCard, { [styles.mobAppCard__faqPage]: faqPageStyle })}>
          {isMobile && <PCLogo />}
          {faqPageStyle && (
            <Image
              className={styles.mobAppCard__faqPage__phone}
              src='/assets/img/footer/pc_desktop_phone.png'
              alt='Not found'
              width={550}
              height={550}
            />
          )}
          <div className={cn(styles.mobAppCard__textblock, { [styles.mobAppCard__faqPage__textbloc]: faqPageStyle })}>
            {!faqPageStyle ? (
              <div className={styles.mobAppCard__title}>
                <span className={styles.mobAppCard__title__mobile}>Мобильное</span>
                <span className={styles.mobAppCard__title__app}>приложение</span>
              </div>
            ) : (
              <div className={styles.mobAppCard__faqPage__title}>Мобильное приложение</div>
            )}
            <div className={styles.mobAppCard__info}>
              <span>Управляй связью</span>
            </div>
          </div>
          <Button type='button' classNames={{ root: cn(styles.mobAppBtn, styles.mobAppBtn__root) }}>
            Перейти
          </Button>
        </Link>
      ) : null}

      {withoutFooter ?  null : <Socials className={styles.socialBlock} />}
      <ConnectionQualityModal
        showModal={showConnectionQualityModal}
        handleCloseModal={handleCloseConnectionQualityModal}
      />
    </div>
  );
});
FooterCardsBlock.displayName = 'FooterCardsBlock';
