import { FC } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { useAppSelector } from '@/store/hooks';
import styles from './agreement-link.module.scss';

interface AgreementLinkProps {
  text: string;
  linkText: string;
  className?: string;
}

export const AgreementLink: FC<AgreementLinkProps> = ({ text, linkText, className }) => {
  const { privacy_policy } = useAppSelector(({ app }) => app);

  const parts = text.split(linkText);
  if (parts.length !== 2) {
    console.error(
      'Ошибка: linkText должен встречаться ровно один раз в тексте.'
    );

    return <span className={cn(styles.text, className)}>{text}</span>;
  }
  
  return (
    <span className={cn(styles.text, className)}>
      {parts[0]}
      <Link href={privacy_policy} target="_blank" className={styles.link}>
        {linkText}
      </Link>
      {parts[1]}
    </span>

  );
};
