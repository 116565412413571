import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Button } from '@mantine/core';
import styles from './loading-error.module.scss';

export default function LoadingError() {
  const router = useRouter();

  const reloadPage = () => {
    router.replace(router.asPath);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.leftBlock}>
          <div className={styles.info}>
            <div className={styles.title}>Что-то пошло не так...</div>
            <div className={styles.text}>
              Попробуй перезагрузить страницу или вернись чуть позже.
            </div>
            <Button
              classNames={{ root: styles.button }}
              onClick={reloadPage}
            >
              Перезагрузить
            </Button>
          </div>
        </div>
        <Image className={styles.image} src='/assets/img/500.png' alt='Not found' width={310} height={280} />
      </div>
    </div>
  );
}
