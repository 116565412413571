import { FC, ReactNode } from 'react';
import cn from 'classnames';
import { MetaData } from '@/store/api/meta/meta.type';
import { Breadcrumbs, BreadcrumbsList } from '../breadcrumbs/breadcrumbs';
import { PageMeta } from '../page-meta/page-meta';
import styles from './page-layout.module.scss';

interface PageLayoutProps {
  children?: ReactNode;
  breadcrumbsList?: BreadcrumbsList;
  meta_data?: MetaData;
  classNames?: {
    container?: string;
    content?: string;
  }
}

export const PageLayout: FC<PageLayoutProps> = ({ breadcrumbsList, children, meta_data, classNames }) => {
  return (
    <div className={cn(styles.container, classNames?.container)}>
      <PageMeta { ...meta_data } />
      {breadcrumbsList ? <Breadcrumbs list={breadcrumbsList} /> : null}
      <div className={cn(styles.content, classNames?.content)}>
        {children || null}
      </div>
    </div>
  );
};
