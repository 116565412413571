import { FC, DOMAttributes, RefObject } from 'react';
import { Button as MantineButton, ButtonProps as MantineButtonProps } from '@mantine/core';
import cn from 'classnames';
import styles from './button.module.scss';

type ButtonProps = {
  size?: 'm' | 's' | 'xs';
  rootClassName?: string;
  ref?: RefObject<HTMLButtonElement>;
  btnType?: 'primary' | 'magenta' | 'base'
} & MantineButtonProps &
  DOMAttributes<HTMLButtonElement>;

export const Button: FC<ButtonProps> = ({ size = 'm', rootClassName, btnType='primary', ...props }) => {
  const sizeClassName = styles['size__' + size];

  return (
    <MantineButton
      classNames={{
        root: cn(styles.root, { [styles.magenta]: btnType === 'magenta', [styles.base]: btnType === 'base' }, sizeClassName, rootClassName),
        label: cn(styles.label, { [styles.label__small]: size === 'xs' }),
      }}
      loaderProps={{ size: 16 }}
      {...props}
    >
      {props.children}
    </MantineButton>
  );
};
