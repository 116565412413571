import React, { FC, memo } from 'react';
import { Textarea as MantineTextarea, TextareaProps } from '@mantine/core';
import cn from 'classnames';
import styles from './textarea.module.scss';

interface Props extends TextareaProps {
  darkBackground?: boolean;
  baseColor?: boolean;
}

const Textarea: FC<Props> = memo(({ darkBackground, className, baseColor, ...props }) => {
  return (
    <MantineTextarea
      classNames={{
        root: cn(styles.wrapper__root, { [styles.dark]: darkBackground }, className),
        label: styles.wrapper__label,
        error: styles.wrapper__error,
        input: cn(styles.input__input, { [styles.base]: baseColor }),
        wrapper: styles.input__wrapper,
      }}
      {...props}
    />
  );
});

Textarea.displayName = 'Textarea';
export { Textarea };
