import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MobileTariffMappedOptions } from '@/modules/tariff/shared/helpers';
import { ServiceEntity } from '../api/service/service.type';

export type MobileTariffSliceItem = Pick<
  MobileTariffMappedOptions,
  | 'localized_name'
  | 'name'
  | 'text'
  | 'shpd_id_apartment'
  | 'shpd_id_house'
  | 'optionsMobile'
  | 'price'
  | 'promotion_price'
  | 'shpd_prices'
  | 'features'
> & { affects_shpd_promotion?: number[]; offer_file?: string; shpd_pdf?: string };

export type MobileService = Pick<ServiceEntity, 'serv_id' | 'shpd_serv_id' | 'title' > & {
  added: boolean;
  servicePrice: number;
  oldPrice?: number;
  period?: string;
  description: string;
};

const initialState = {
  status: {
    added: false,
    touched: false,
  },
  mobileTariff: <MobileTariffSliceItem>{
    localized_name: '',
    name: '',
    text: '',
    shpd_prices: {
      promotion_price: null,
      evpatoria_price: 0,
      price_apartment: 0,
      price_house: 0,
    },
    price: 0,
    promotion_price: undefined,
    shpd_id_apartment: 0,
    shpd_id_house: 0,
    optionsMobile: [],
    affects_shpd_promotion: [],
    offer_file: '',
    shpd_pdf: '',
    features: [],
  },
  mobileTouchedServices: <MobileService[]>[],
  basePriceMobile: 0,
  totalPriceMobile: 0,
  promotionBasePriceMobile: <number | undefined | null>null,
  promotionTotalPriceMobile: <number | undefined | null>null,
};

export const MobileTariffSlice = createSlice({
  name: 'mobileTariff',
  initialState,
  reducers: {
    changeMobileStatus: (state, action: PayloadAction<boolean>) => {
      state.status.added = action.payload;
      state.status.touched = true;
    },
    changeMobileTariff: (state, action: PayloadAction<MobileTariffSliceItem>) => {
      state.status.added = true;
      state.status.touched = true;
      state.mobileTariff = action.payload || initialState.mobileTariff;
      state.mobileTouchedServices = initialState.mobileTouchedServices;
    },
    resetMobileTariffToInitial: (state) => {
      state.status.added = false;
      state.status.touched = false;
      state.mobileTariff = initialState.mobileTariff;
      state.mobileTouchedServices = initialState.mobileTouchedServices;
      state.basePriceMobile = initialState.basePriceMobile;
      state.totalPriceMobile = initialState.totalPriceMobile;
      state.promotionBasePriceMobile = initialState.promotionBasePriceMobile;
      state.promotionTotalPriceMobile = initialState.promotionTotalPriceMobile;
    },
    changeMobileServices: (state, action: PayloadAction<MobileService[]>) => {
      state.mobileTouchedServices = action.payload;
    },
    changeBasePriceMobile: (state, action: PayloadAction<number>) => {
      state.basePriceMobile = action.payload;
    },
    changeTotalPriceMobile: (state, action: PayloadAction<number>) => {
      state.totalPriceMobile = action.payload;
    },
    changePromotionBasePriceMobile: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionBasePriceMobile = action.payload;
    },
    changePromotionTotalPriceMobile: (state, action: PayloadAction<number | undefined | null>) => {
      state.promotionTotalPriceMobile = action.payload;
    },
  },
});

export const {
  changeMobileStatus,
  resetMobileTariffToInitial,
  changeMobileTariff,
  changeMobileServices,
  changeBasePriceMobile,
  changeTotalPriceMobile,
  changePromotionBasePriceMobile,
  changePromotionTotalPriceMobile,
} = MobileTariffSlice.actions;

export default MobileTariffSlice.reducer;
