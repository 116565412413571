import { ReactNode, useState } from 'react';
import cn from 'classnames';
import { Header } from '@/widgets/header/header';
import { Footer } from '@/widgets/footer/footer';
import { FooterCardsBlock } from '@/widgets/footer-cards/footer-cards';
import { Sidebar } from '@/widgets/sidebar/sidebar';
import { AboutCompanyEntity } from '@/store/api/about-company/about-company.type';
import { useLegalData } from '@/shared/constants/use-legal-data';
import styles from './layout.module.scss';

export function Layout({ children, legalData }: { children: ReactNode, legalData?: AboutCompanyEntity }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  useLegalData(legalData);

  const onSidebarOpen = () => {
    setSidebarOpen(true);
  };

  const onSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.centralColumn}>
        <div className={cn(styles.header, sidebarOpen && styles.headerSidebarOpened)}>
          <Header>
            <Sidebar isOpen={sidebarOpen} onOpen={onSidebarOpen} onClose={onSidebarClose} />
          </Header>
        </div>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <FooterCardsBlock />
          <Footer />
        </div>
      </div>
    </div>
  );
}
