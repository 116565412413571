import React from 'react';

export const Paperclip = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8448 3.15524C10.6879 1.99838 8.81229 1.99838 7.65543 3.15524L4.09294 6.71774C2.21121 8.59946 2.21121 11.6503 4.09294 13.5321C5.97466 15.4138 9.02555 15.4138 10.9073 13.5321L15.9698 8.46958C16.2627 8.17668 16.7375 8.17668 17.0304 8.46958C17.3233 8.76247 17.3233 9.23734 17.0304 9.53024L11.9679 14.5927C9.50042 17.0602 5.49979 17.0602 3.03228 14.5927C0.564762 12.1252 0.564762 8.12459 3.03228 5.65708L6.59477 2.09458C8.33741 0.35194 11.1628 0.351935 12.9054 2.09458C14.6481 3.83722 14.6481 6.6626 12.9054 8.40524L9.34293 11.9677C8.32517 12.9855 6.67504 12.9855 5.65728 11.9677C4.63951 10.95 4.63951 9.29984 5.65728 8.28208L9.21978 4.71958C9.51267 4.42668 9.98754 4.42668 10.2804 4.71958C10.5733 5.01247 10.5733 5.48734 10.2804 5.78024L6.71794 9.34274C6.28596 9.77472 6.28596 10.4751 6.71794 10.9071C7.14992 11.3391 7.85029 11.3391 8.28227 10.9071L11.8448 7.34458C13.0016 6.18772 13.0016 4.31209 11.8448 3.15524Z'
        fill='currentColor'
      />
    </svg>
  );
};
