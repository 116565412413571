import { useEffect } from 'react';
import { useGetAboutCompanyQuery } from '@/store/api/about-company/about-company.api';
import { AboutCompanyEntity } from '@/store/api/about-company/about-company.type';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { changePrivacyPolicy } from '@/store/slice/app.slice';
import { apiEndpoints } from './links';

export const useLegalData = (legalData?: AboutCompanyEntity) => {
  const { data: clientData } = useGetAboutCompanyQuery({ populate: 'privacy_policy' }, { skip: !!legalData });
  const { privacy_policy } = useAppSelector(({ app }) => app);
  const dispatch = useAppDispatch();

  const currentData = legalData || clientData;
  const privacyPolicy = currentData?.data?.attributes.privacy_policy?.data?.[0];
  const url = privacyPolicy ? `/${apiEndpoints.files}/${privacyPolicy?.attributes?.hash}${privacyPolicy?.attributes?.ext}` : '';

  useEffect(() => {
    if (!privacy_policy) {
      dispatch(changePrivacyPolicy(url));
    }
  }, [dispatch, privacy_policy, url]);
};