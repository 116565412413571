import { ReactNode } from 'react';
import { Image } from '@mantine/core';
import { Media } from '@/shared/types/strapi.types';
import { CarouselWithTitle, CarouselWithTitleStyles } from '../carousel-with-title';

interface BannersCarouselProps {
  banners: Media['attributes'][];
  title: ReactNode;
  classNames?: CarouselWithTitleStyles;
}

export const BannersCarousel: React.FC<BannersCarouselProps> = ({ title, banners, classNames }) => {
  const slides = banners.map((data, idx) => (
    <Image
      key={idx}
      src={data?.url}
      alt={data?.alternativeText || `slide ${idx + 1}`}
    />
  ));

  return (
    <CarouselWithTitle title={title} slides={slides} classNames={classNames} />
  );
};
