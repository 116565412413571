import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, getOrigin } from '@/shared/lib/base-query';
import { apiEndpoints } from '@/shared/constants/links';

export const emailApi = createApi({
  reducerPath: 'emailApi',
  baseQuery: baseQuery(getOrigin() + apiEndpoints.email),
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: (data) => ({
        url: '/new',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useSendEmailMutation } = emailApi;
